import React from 'react'
import "./Footer.css";
import footlogo from "../../assets/ATMOSLOGO.svg"

import { FaInstagramSquare } from "react-icons/fa";
import { FaSquareYoutube } from "react-icons/fa6";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { FaGithubSquare } from "react-icons/fa";
import { BiSolidRightArrow } from "react-icons/bi";
import { FaRegCopyright } from "react-icons/fa";
import { MdEmail } from "react-icons/md";







const Footer = () => {
  return (
    <footer>
      <div className="footer">
        <div className="footer-box">
          <div className="footer-box-social">
            <div></div>
            <a onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
              <img src={footlogo} alt="Logo" className="footer-logo"/> 
              <p1 className="footer-logo-text">S A I O S</p1>
            </a>
            <div>
            <p>The First 3D Computer; Navigate It With Our Simple Artificially Intelligent Operating System</p>
            </div>
            <div className="footer-social">
              <a href="https://saios.xyz" className="icons">
                <FaSquareYoutube size="30px" color="#fff" title="Follow Youtube Link"/>
              </a>
              <a href="https://github.com/carterscherer/sai" className="icons">
                <FaGithubSquare size="30px" color="#fff" title="Follow Git Hub Link" />
              </a>
              <a href="mailto:carterjohnscherer@gmail.com?subject=SAIOS%20Computer%20Inquiry&body=Dear%20Saios," className="icons">
                <MdEmail size="30px" color="#fff" title="Follow Git Hub Link" />
              </a> 
              <a href="tel:+16502799135" className="icons">
                <FaPhoneSquareAlt size="30px" color="#fff" title="Give us a call!" />
              </a>
              <a href="https://instagram.com/saios.xyz" className="icons">
                <FaInstagramSquare size="30px" color="#fff" title="Follow Git Hub Link"/>
              </a>
            </div>
            </div>
          

            <div className="footer-information">
              <h2>Developers</h2>
              <a href="https://github.com/carterscherer/sai"><li>Our Use of Ai</li></a>
              <a href="https://github.com/carterscherer/sai"><li>Open Source</li></a>
              <a href="https://saios.xyz"><li>Sai Hardware</li></a>
              <a href="https://github.com/carterscherer/sai"><li>Blockchain</li></a>
            </div>
            <div className="footer-information">
              <h2>Investors</h2>
              <a href="https://saios.xyz"><li>Relations</li></a>
              <a href="https://saios.xyz"><li>About Us</li></a>
              <a href="https://saios.xyz"><li>Pitch Deck</li></a>
              <a href="https://saios.xyz"><li>Contact Us</li></a>
            </div>
            <div className="footer-information">
              <h2>Resources</h2>
              <a href="https://github.com/carterscherer/sai"><li>Sai Github</li></a>
              <a href="https://figma.com/carterscherer/sai"><li>Figma</li></a>
              <a href="https://saios.xyz"><li>Saios.eth</li></a>
              <a href="https://saios.xyz"><li>Our Story</li></a>
            </div>
        
          <div>
          <div className="footer-signup-title"><h2>Sign Up For Our Newsletter</h2></div>
            <div className="footer-signup-box">
              
              <div className="footer-signup">
                <input type="email" placeholder='Enter Your Email'/>
                </div>
                <BiSolidRightArrow size="30px" classname='footer-send'/>
              </div>
              <div className="footer-signup-info">
              <p>Sign up to receive updates for when we launch</p>
            </div>
            
          </div>
          
        </div>
      </div>
      <div className="watermark-box">
        <div>
        <FaRegCopyright className='watermark-copyright'/>
        </div>
        <div classname="watermark">
        <div>
        <p3>Saios Inc. </p3>
        </div></div>
        <p3></p3>
        <div></div>
   

      </div>
    </footer>
  )
}

export default Footer
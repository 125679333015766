import React, { useState } from 'react'
import "./Waitlist.css";
import JoinPopUp from '../JoinPopUp/JoinPopUp';


const Waitlist = () => {

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };


  return (
    <waitlist>
    <div className="wrapper">
     
        <div className="cta">
            <p className="headline">sai'n up for updates</p>
        </div>
      
        <form onSubmit={openModal} classname="FormGroup" action="/signup/" method="POST">
          <input classname="EmailInput" name="email" type="email" placeholder="holographic@example.com"/>
          <button name="join-button">JOIN</button>
        </form>
            {showModal && <JoinPopUp onClose={closeModal} />} 
      </div>  
      </waitlist>    
  )
}


export default Waitlist


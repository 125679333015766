import React from 'react';
import "./JoinPopUp.css";
import styled from "styled-components";

const FullPageOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999; /* Make sure it's above other elements */
`;

const JoinPopUp = ({ onClose }) => {

    const handleModalClose = () => {
        console.log("Modal is closing");
        onClose();
    };
    const onClickAnywhere = () => {
        // This function will be called when you click anywhere on the page
        window.location.href = "https://saios.xyz";
      };


  return (
    <div className="glass-modal active">
      <div className="modal-content">
        <p className="close" onClick={handleModalClose}>
       
        </p>
        <div className="emoji">🌀</div>
        <p>Thank you for joining!</p>
        <h5>You will hear from us when the Sai is available!! </h5>
      </div>
      <FullPageOverlay onClick={onClickAnywhere} />
    </div>

  );
};

export default JoinPopUp;

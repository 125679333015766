import React, { useState } from 'react';
import './Header.css';
import logo from '../../assets/ATMOSLOGO.svg';
import LowBit1080 from '../../assets/matte_blue_sai_24.mp4';
import LowBit720 from '../../assets/matte_blue_sai_24.mp4';
import { MenuItems } from "../Navbar/MenuItems"
import { ImMenu } from "react-icons/im";


// Define an array of video sources
const videoSources = [

  { src: LowBit1080, type: 'video/mp4' },
  { src: LowBit720, type: 'video/mp4' },
  
];

const Header = () => {

  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
      setClicked(!clicked);
  };

  return (
    <header>
      <div className="logo-container">

      <a href="" target="_top"><img src={logo} alt="Logo" className="logo" /></a>
        <div ></div>
        <ImMenu className="menu-icon" onClick={handleClick}/>

        <div className="header-navigation">
        <div ></div>
        {/* Menu */}
        <ul className={`menu ${clicked ? 'active' : ''}`}>
          {/* Menu items */}
          {MenuItems.map((item, index) => (
            <li key={index}>
              <a className={item.cName} href={item.url}>{item.title}</a>
            </li>
          ))}
        </ul>
        </div>
     
      </div>

      <div className="media-container">
        
        <div className="video-container">
          {/* Try all video types decending in size and quality */}
          {videoSources.map((videoSource, index) => (
            <video key={index} src={videoSource.src} 
              autoPlay
              loop
              muted
              preload="auto"
              playsInline
             />
          ))}
        </div>


        <div className="cta">
          <p className="headline">THE FIRST VOLUMETRIC COMPUTER</p>
        </div>
      </div>
    </header>
  );
};

export default Header;

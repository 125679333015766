import React from 'react';
import "./Products.css";
import PayCard from '../../components/PayCard/PayCard';


const Products = () => {


  return (
    <products>
        <div className="box">
            <div className="card_space">
                <div className="left_card">
                    {/* image & price */}
                    <PayCard />
                </div>
                <div className="right_card">
                    {/* name, decription, cta */}
                    {/* <h1>SaaS</h1> */}
                </div>
            </div>
        </div>  
    </products>    
  )
}


export default Products;
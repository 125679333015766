import React from 'react'
import { Header, Waitlist, Footer } from './components'
import Products from './components/Products/Products';


const App = () => {
  return (
    <>
      <Header   id="home"/> 
      <Products id="products"/>
      <Waitlist id="waitlist"/>
      <Footer   id="footer"/>
    </>
  )
}


export default App
